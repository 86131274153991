<template>
  <div class="fecha">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M21.9375 4.21875H5.0625C3.66453 4.21875 2.53125 5.35203 2.53125 6.75V21.9375C2.53125 23.3355 3.66453 24.4688 5.0625 24.4688H21.9375C23.3355 24.4688 24.4688 23.3355 24.4688 21.9375V6.75C24.4688 5.35203 23.3355 4.21875 21.9375 4.21875Z"
        stroke="#9B9A9A"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M15.2656 13.5312C15.9646 13.5312 16.5312 12.9646 16.5312 12.2656C16.5312 11.5666 15.9646 11 15.2656 11C14.5666 11 14 11.5666 14 12.2656C14 12.9646 14.5666 13.5312 15.2656 13.5312Z"
        fill="#9B9A9A"
      />
      <path
        d="M19.8281 13.5C20.5271 13.5 21.0938 12.9334 21.0938 12.2344C21.0938 11.5354 20.5271 10.9688 19.8281 10.9688C19.1291 10.9688 18.5625 11.5354 18.5625 12.2344C18.5625 12.9334 19.1291 13.5 19.8281 13.5Z"
        fill="#9B9A9A"
      />
      <path
        d="M15.6094 17.7188C16.3084 17.7188 16.875 17.1521 16.875 16.4531C16.875 15.7541 16.3084 15.1875 15.6094 15.1875C14.9104 15.1875 14.3438 15.7541 14.3438 16.4531C14.3438 17.1521 14.9104 17.7188 15.6094 17.7188Z"
        fill="#9B9A9A"
      />
      <path
        d="M19.8281 17.7188C20.5271 17.7188 21.0938 17.1521 21.0938 16.4531C21.0938 15.7541 20.5271 15.1875 19.8281 15.1875C19.1291 15.1875 18.5625 15.7541 18.5625 16.4531C18.5625 17.1521 19.1291 17.7188 19.8281 17.7188Z"
        fill="#9B9A9A"
      />
      <path
        d="M7.17188 17.7188C7.87086 17.7188 8.4375 17.1521 8.4375 16.4531C8.4375 15.7541 7.87086 15.1875 7.17188 15.1875C6.47289 15.1875 5.90625 15.7541 5.90625 16.4531C5.90625 17.1521 6.47289 17.7188 7.17188 17.7188Z"
        fill="#9B9A9A"
      />
      <path
        d="M11.3906 17.7188C12.0896 17.7188 12.6562 17.1521 12.6562 16.4531C12.6562 15.7541 12.0896 15.1875 11.3906 15.1875C10.6916 15.1875 10.125 15.7541 10.125 16.4531C10.125 17.1521 10.6916 17.7188 11.3906 17.7188Z"
        fill="#9B9A9A"
      />
      <path
        d="M7.17188 21.9375C7.87086 21.9375 8.4375 21.3709 8.4375 20.6719C8.4375 19.9729 7.87086 19.4062 7.17188 19.4062C6.47289 19.4062 5.90625 19.9729 5.90625 20.6719C5.90625 21.3709 6.47289 21.9375 7.17188 21.9375Z"
        fill="#9B9A9A"
      />
      <path
        d="M11.3906 21.9375C12.0896 21.9375 12.6562 21.3709 12.6562 20.6719C12.6562 19.9729 12.0896 19.4062 11.3906 19.4062C10.6916 19.4062 10.125 19.9729 10.125 20.6719C10.125 21.3709 10.6916 21.9375 11.3906 21.9375Z"
        fill="#9B9A9A"
      />
      <path
        d="M15.6094 21.9375C16.3084 21.9375 16.875 21.3709 16.875 20.6719C16.875 19.9729 16.3084 19.4062 15.6094 19.4062C14.9104 19.4062 14.3438 19.9729 14.3438 20.6719C14.3438 21.3709 14.9104 21.9375 15.6094 21.9375Z"
        fill="#9B9A9A"
      />
      <path
        d="M6.75 2.53125V4.21875M20.25 2.53125V4.21875"
        stroke="#9B9A9A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.4688 8.4375H2.53125"
        stroke="#9B9A9A"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
    <h5>
      {{ this.fechaCompleta }}
    </h5>
  </div>
</template>
<script>
export default {
  name: "FechaComponent",
  data() {
    return {
      fechaCompleta: "",
      fecha: "",
      dia: "",
      mes: "",
      nombreDia: "",
    };
  },
  created() {
    this.fecha = new Date();
    this.dia = this.fecha.getDate();
    this.getMes(this.fecha.getMonth());
    this.getDia(this.fecha.getDay());
    this.fechaCompleta = `${this.nombreDia} ${this.dia} de ${this.mes}`;
  },
  methods: {
    getMes(mes) {
      switch (mes) {
        case 0:
          this.mes = "Enero";
          break;
        case 1:
          this.mes = "Febrero";
          break;
        case 2:
          this.mes = "Marzo";
          break;
        case 3:
          this.mes = "Abril";
          break;
        case 4:
          this.mes = "Mayo";
          break;
        case 5:
          this.mes = "Junio";
          break;
        case 6:
          this.mes = "Julio";
          break;
        case 7:
          this.mes = "Agosto";
          break;
        case 8:
          this.mes = "Septiembre";
          break;
        case 9:
          this.mes = "Octubre";
          break;
        case 10:
          this.mes = "Noviembre";
          break;
        case 11:
          this.mes = "Diciembre";
          break;

        default:
          break;
      }
    },
    getDia(dia) {
      switch (dia) {
        case 1:
          this.nombreDia = "Lunes";
          break;
        case 2:
          this.nombreDia = "Martes";
          break;
        case 3:
          this.nombreDia = "Miércoles";
          break;
        case 4:
          this.nombreDia = "Jueves";
          break;
        case 5:
          this.nombreDia = "Viernes";
          break;
        case 6:
          this.nombreDia = "Sábado";
          break;
        case 7:
          this.nombreDia = "Domingo";
          break;

        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.fecha {
  display: flex;
  flex-direction: row;
  gap: 6px;
  position: absolute;
  /* padding-top: 3vh; */
  /* text-align: right; */
  /* padding-right: 3.5vw; */
  z-index: 4;
  top: 132px;
  left: 1132px;
}
.fecha h5 {
  letter-spacing: 0px;
  font-size: 13px;
  color: #9b9a9a;
  margin-top: 5px;
}
.bi {
  font-size: 27px;
  margin-right: 1%;
}
@media (max-width: 770px) {
  .fecha {
    display: none;
  }
}
</style>
