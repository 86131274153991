<template>
  <div class="navMuni">
    <router-link to="/muni-agentes">
      <img src="@/assets/images/logo-muni.svg" alt="" class="logo-muni" />
    </router-link>
    <div class="usuario">
      <div class="usuario-details" v-if="$store.state.user">
        <div>
          <h4>
            ¡Hola <strong>{{ $store.state.user.firstname }}!</strong>
          </h4>
        </div>
      </div>
    </div>
    <div class="container">
      <router-link to="/muni-agentes">
        <img
          src="@/assets/images/MuniEnLinea.svg"
          alt=""
          class="logo scale-up-center"
        />
      </router-link>
      <div class="botonesNav">
        <div
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <img
            src="@/assets/images/Notificaciones.svg"
            class="img"
            @click="getNotificaciones"
            style="cursor: pointer"
          />
        </div>
        <div @click="openLogoutModal" class="botonOut">
          <img src="@/assets/images/IconOff.svg" class="img" />
        </div>
      </div>
      <!-- MODAL DE REPORTES-->
      <!-- <div
        class="modal-overlay-reports"
        v-if="isReportModalOpen"
        @click.self="closeModal"
      >
        <div class="modalReports" v-if="!sendR">
          <div class="modal-content" style="padding: 50px">
            <div class="modalTittle" style="text-align: center">
              <h1>¿Encontraste algún error en la web?</h1>
              <p>Ayudanos reportando el problema</p>
            </div>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
            ></button>
            <div class="modal-body-reports">
              <div v-if="!sendR">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  v-model="report"
                  class="input"
                  maxlength="250"
                ></textarea>
              </div>
              <button
                class="botonVerde"
                @click="sendReport"
                :disabled="report ? false : true"
                v-if="!sendR"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>

        <div class="modalReportsFinalized" v-else>
          <div class="modal-content" style="padding: 50px">
            <div class="modalTittle" style="align-items: flex-start">
              <h1 style="color: var(--Verde, #00c3a8)">
                Gracias por reportar el error
              </h1>
              <p>
                Estamos trabajando para solucionarlo lo antes posible <br />
                <strong style="display: flex; align-items: flex-start">
                  ¡Tu colaboración hace la diferencia!</strong
                >
              </p>
            </div>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
            ></button>
          </div>
        </div>
      </div> -->
      <!-- MODAL DE CIERRE DE SESION -->
      <div
        v-if="isLogoutModalOpen"
        class="custom-modal-overlay"
        @click.self="closeModal"
      >
        <div class="custom-modal">
          <div class="modal-body">
            <h5 style="margin-top: -8px">
              <i
                class="bi bi-exclamation-circle"
                style="color: #ffb900; margin-right: 8px; font-size: 26px"
              ></i>
              ¿Está seguro que desea salir?
            </h5>
          </div>
          <div class="footer">
            <p @click="closeModal" style="cursor: pointer">Rechazar</p>
            <button type="button" class="botonSubmit" @click="cerrarSesion">
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      data-bs-scroll="true"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
          "
        >
          <div class="botonNotificacionCanvas">
            <i class="bi bi-bell" style="width: 32px; height: 32px"> </i>
          </div>
          <h5 id="offcanvasRightLabel">Notificaciones</h5>
        </div>

        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body" v-if="this.notificaciones">
        <div
          v-for="(item, index) in this.notificaciones"
          :key="index"
          class="notificacion"
        >
          <div style="width: 75%">
            <h6 style="word-break: break-all">{{ item.subject }}</h6>
            <p>{{ item.message }}</p>
          </div>

          <h6 class="fecha">
            <!-- {{ new Date(item.created_at).getDate() }}/{{
              new Date(item.created_at).getMonth() + 1
            }}/{{ new Date(item.created_at).getFullYear() }} -->
            {{ new Date(item.created_at).toLocaleDateString() }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import setTokenMuni from "./../../../middlewares/setTokenMuni";
import { BASE_URL } from "@/env";

export default {
  name: "NavTopMunicipalComponet",
  data() {
    return {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      lastName: localStorage.getItem("lastname"),
      avatar: this.$store.state.user_muni?.avatar,
      modalFiltros: false,
      vista: false,
      report: "",
      sendR: false,
      activos: [],
      deadline: [],
      requeridos: [],
      finalizados: [],
      isReportModalOpen: false,
      isLogoutModalOpen: false,
      modal: false,
      modalTarea: false,
      modalComunicacion: false,
      modalRequerimiento: false,
      modalComunicacionFinal: false,
      modalPdf: false,
      selectedHistory: null,
      selectedTramite: null || "",
      history: [],
      paginas: null,
      paginaActual: 1,
      status: "",
      message: false,
      requerimiento: null,
      modalresponse: false,
      search: "",
      messageBuscar: false,
      datosEnviados: false,
      cuilUserNotificacion: "",
      level: "",
      notificaciones: null,
    };
  },
  created() {
    this.getProcedures();
  },
  methods: {
    openReportModal() {
      this.isReportModalOpen = true;
    },
    openLogoutModal() {
      this.isLogoutModalOpen = true;
    },
    closeModal() {
      this.isReportModalOpen = false;
      this.isLogoutModalOpen = false;
      this.sendR = false;
    },
    sendReport() {
      console.log(this.report, "soy el reporte");
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient
        .post("/feedback/bug-report/muni", {
          report: this.report,
        })
        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            this.sendR = true;
          }
          this.report = "";
        })
        .catch((error) => {
          this.msj = error.response.data.message;
          console.log(error.data);
        });
    },
    cerrarSesion() {
      this.closeModal();

      // var backdrop = document.getElementsByClassName("modal-backdrop")[0];
      // backdrop.parentNode.removeChild(backdrop);
      // setTimeout(() => {
      this.logOf();
      // }, 2000);
    },
    logOf() {
      localStorage.clear();
      document.cookie = "cidi=; max-age=0";
      this.dispatchOutLogin();
      this.$router.push("/login");
      // this.$router.push("/");
    },
    dispatchOutLogin() {
      this.$store.dispatch("mockOutAction");
    },
    setModalFiltros() {
      this.modalFiltros = !this.modalFiltros;
    },
    SetVistaLista() {
      this.vista = !this.vista;
    },
    getProcedures() {
      this.activos = [];

      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });

      apiClient
        .get("/oficina/procedures/history")
        .then((response) => {
          console.log(response.data);
          let plazo = response.data.HistoryOfProcedures.OnTime;
          let fueraPlazo = response.data.HistoryOfProcedures.OutTime;
          let requeridos = response.data.HistoryOfProcedures.PausedTime;

          this.activos = [];
          this.deadline = [];
          this.requeridos = [];

          for (let i = 0; i < plazo.length; i++) {
            this.activos.push(plazo[i]);
          }

          for (let i = 0; i < fueraPlazo.length; i++) {
            this.deadline.push(fueraPlazo[i]);
          }

          for (let i = 0; i < requeridos.length; i++) {
            this.requeridos.push(requeridos[i]);
          }

          this.history = [];
          this.history = this.history
            .concat(this.activos)
            .concat(this.deadline)
            .concat(this.requeridos);
          this.message = false;
        })
        .catch((error) => {
          console.log(error, "algo paso");
          if (error.response.status === 500) {
            if (error.response.data.message === "Token de usuario expirado") {
              setTokenMuni();
              this.getProcedures();
            }
          }
        });
    },
    verTramite(id) {
      this.selectedTramite = id;

      let apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient
        .get("/oficina/procedures/history/" + id)
        .then((response) => {
          console.log(response.data);
          this.selectedHistory = response.data.Procedure;
          let asd = response.data["PDFData"];
          if (asd) {
            this.pdf = asd;
          }
          if (response.data.Procedure.status.status === "PRESENTADO") {
            this.status = "2";
            this.updateStatus();
            this.activos = [];
            this.getProcedures();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            if (error.response.data.message === "Token de usuario expirado") {
              setTokenMuni();
              this.verTramite(id);
            }
          }
        });

      this.modal = true;
    },
    getNotificaciones() {
      let apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient.get("/communications/muni-communications").then((response) => {
        console.log(response.data, "soy las comunicaciones del municipal");
        this.notificaciones = response.data.Communications;
      });
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Valor alto */
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-overlay-reports {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalReports {
  display: flex;
  position: fixed;
  top: 20%;
  left: 22%;
  width: 850px;
  height: 454px;
  background: #ffff;
  align-items: center;
  border-radius: 25px;
  gap: 38px;
}
.modalReportsFinalized {
  display: flex;
  position: fixed;
  top: 20%;
  left: 22%;
  width: 850px;
  height: 230px;
  background: #ffff;
  align-items: center;
  border-radius: 25px;
  gap: 38px;
}
.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.input {
  display: flex;
  height: 173px;
  width: 600px;
  align-items: center;
  gap: 38px;
  align-self: stretch;
  border: none;
  border-radius: 19px;
  background: var(--Gris-claro, #f5f5f5);
}
.botonVerde {
  margin-top: 20px;
  display: flex;
  width: 337px;
  height: 49px;
  padding: 0px 13px 0px 12.802px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 9.342px;
  background: #00c3a8;
  color: var(--blanco, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 22.404px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.407px; /* 171.429% */
}

.modal-body-reports {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-body {
  padding: 20px;
}
.modal-body h5 {
  color: var(--Gris-Oscuro, #3e3e3e);
}
.navMuni {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  padding: 0px 32px;
  gap: 20px;
  flex-shrink: 0;
  background: var(--blanco, #fff);
  position: fixed;
  z-index: 2;
}

strong {
  font-family: Montserrat;
  font-size: 21.88px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.logo {
  width: 92.966px;
  height: 48px;
}
.imagenlogo {
  width: 191px;
  height: 58px;
  position: relative;
  margin-left: 2.6vw;
}
.filtro-img {
  max-width: 30px;
  margin-left: 1rem;
}

.usuario {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: left;
  margin-left: 6vw;
  margin-top: 10px;
}

.logo-muni {
  display: flex;
  width: 191px;
  height: 58px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.offcanvas-end {
  /* width: 506px; */
  border-radius: 16px 0px 0px 16px;
  background: #fff;
  padding: 32px 24px;
}
.offcanvas-body {
  /* width: 458px; */
  /* border-radius: 16px 0px 0px 16px; */
  /* background: #fff; */
  /* padding: 32px 24px; */
}
.botonNotificacionCanvas {
  background: var(--gris);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  color: whitesmoke;
  text-align: center;
  padding: 4px;
  font-size: 18px;
  transform: rotate(40deg);
}
.offcanvas-header h5 {
  color: var(--verdePrincipal);
  font-size: 17.5px;
  font-style: normal;
  font-weight: 600;
  /* margin-left: -100px; */
}
.offcanvas-body h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 4px;
}
.offcanvas-body p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.offcanvas-body h6:hover {
  color: var(--verdePrincipal);
}
.btn-close {
  position: absolute;
  right: 24px;
  top: 32px;
}
.notificacion {
  border-bottom: 1px solid var(--bordecard);
  margin-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 32px;
  word-break: break-all;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 8px;
  /* text-align: left; */
}
/*.modal-content {
  border-radius: 32px;
  padding: 32px;
  align-items: center;
  /* width: 835px;
}*/
.modalTittle {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}
.modalTittle h1 {
  color: var(--Gris-Oscuro, #3e3e3e);
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.407px; /* 106.685% */
  letter-spacing: -1.08px;
}
.modalTittle p {
  color: var(--Gris-Oscuro, #3e3e3e);
  text-align: center;
  font-family: Montserrat;
  font-size: 22.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 38.407px; /* 171.429% */
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 32px;
  padding: 15px;
}
.img {
  width: 52px;
  height: 52px;
}
.botonReports {
  cursor: pointer;
}
.botonOut {
  cursor: pointer;
}
.botonesNav {
  display: flex;
  height: 56px;
  align-items: baseline;
  gap: 18px;
}
.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 22vw;
  justify-content: space-between;
  position: absolute;
  right: 5vw;
}
.munienlinea {
  width: 92.966px;
  height: 48px;
}

.usuario-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  font-family: Montserrat;
  text-align: left;
  font-size: 21.88px;
  color: #00c3a8;
  width: 360px;
}

.circuloVerde {
  width: 17.5px;
  height: 17.5px;
  fill: var(--Verde, #00c3a8);
}

.search {
  width: 100%;
  position: relative;
  height: 20px;
  border-color: transparent;
  background: var(--Gris-claro, #f5f5f5);
  padding-left: 16px;
  border-radius: 8px;
  outline: none;
}

::placeholder {
  color: #9b9a9a;
  font-size: 11.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-family: Montserrat;
}

.containerSearch {
  width: 783px;
  position: relative;
  border-radius: 4px;
  background: var(--Gris-claro, #f5f5f5);
  display: flex;
  height: 36px;
  padding: 9.25px 11.5px 9.25px 19px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
}
.dropdown {
  display: none;
}
.nav-container {
  position: fixed;
  height: 108px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 2% 0% 1%;
}
.circuloAvatar img {
  width: 100%;
  height: 100%;
}
h5 {
  color: #ff2745;
}
.fecha {
  position: absolute;
  right: 0;
  top: 0;
}
.modaldos {
  text-align: left;
  height: 300px;
}
.botonSubmit {
  padding: 16px 48px;
  background: var(
    --degrade-VA,
    linear-gradient(90deg, #00c3a8 4.26%, #ffb900 126.04%)
  );
  border-radius: 12px;
  color: white;
  font-size: 17.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-style: none;
}
</style>
